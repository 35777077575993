// import Vue from 'vue'
import { Component } from "vue-property-decorator";
import baseUserContextPage from "./baseUserContextPage";
import { useFaq } from '@/composables';
import { onBeforeMount } from "@vue/composition-api";
import { faqModel } from "@/models/faqModel";

@Component({
  setup(props, ctx) {
    const { faqDetail, getFaqById } = useFaq();

    onBeforeMount(() => {
      getFaqById(ctx.root.$route.params.id)
    })

    return { faqDetail };
  }
})
export default class faqDetailPage extends baseUserContextPage {
  // ...
}
